const configOptions = {
  timeZoneDateString: '',
  currentTimeInString: 'Current Time',
  shortTimeString: '',
  disableImportElectors: true,
  registrationEnabled: false,
  customSecurityAnswer: null,
  securityAnswerLength: 4,
  usernameStartsWith: '5555',
  voteReceiptEnabled: true, // todo
}
export default configOptions
